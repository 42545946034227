


document.addEventListener('DOMContentLoaded', () => {

	require('./main');
	
	// do your setup here
	console.log('Initialized app');

});
