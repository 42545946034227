console.log('Main JS');

const sceneController = (function () {
  let showWork = '';

  // elements to animate
  const elements = {
    viewWork: document.querySelector('.cta--view-work'),
    viewWorkImage: document.getElementsByClassName('slide__intro-image-show'),
    footerCTA: document.querySelector('.footer-cta'),
    slides: document.querySelector('.slides'),
    slideBlockMob: document.querySelector('.slide__block--animate-mobile'),
    slideBlockTab: document.querySelector('.slide__block--animate-tablet'),
    slideImageMob: document.querySelector('.slide__image--animate-mobile'),
    slideImageTab: document.querySelector('.slide__image--animate-tablet'),
    slideIntroImageMob: document.querySelector('.slide__intro-image-mobile'),
    slideIntroImageTab: document.querySelector('.slide__intro-image-tablet'),
    slideCopyMob: document.querySelector('.slide__copy--animate-mobile'),
    slideCopyTab: document.querySelector('.slide__copy--animate-tablet'),
    slideCTA: document.querySelector('.slide__cta--animate'),
    scrollIcon: document.querySelector('.slide__scroll'),
    intro: document.querySelector('.page-content__intro'),
    bodyEl: document.getElementsByTagName('body')[0],
    aboutClose: document.querySelector('.about__close-icon'),
    aboutContent: document.querySelector('.about__content'),
    workClose: document.querySelector('.work__close-icon'),
    work: document.querySelector('.work'),
    workContent: document.querySelector('.work__content'),
    workContentInner: document.querySelector('.work__content-inner'),
    showBtn: document.getElementsByClassName('cta--show'),
    menuIcon: document.querySelector('.menu-icon'),
    navLogo: document.querySelector('.nav__logo'),
    navLinkTitle: document.querySelector('.nav__link--title'),
    navLinkAbout: document.querySelector('.nav__link--about'),
    navLinkWork: document.querySelector('.nav__link--work'),
    menuRow1: document.getElementsByClassName('menu-icon__row--1'),
    menuRow2: document.getElementsByClassName('menu-icon__row--2'),
    menuRow3: document.getElementsByClassName('menu-icon__row--3'),
    yearsSpan: document.querySelector('.yearSpan'),
    newyddionVideo: document.querySelector('.newyddion-video'),
  };

  // various easing functions
  // for greensock
  const easing = {
    linear: Linear.easeNone,
    back1: Back.easeIn.config(1),
    back2: Back.easeIn.config(1.4),
    back3: Back.easeInOut.config(2),
    elastic: Elastic.easeInOut.config(1, 0.3),
  };

  // durations for animations
  const duration = {
    main: 0.3,
  };

  // show/hide class
  const showSlideScroll = function () {
    elements.bodyEl.classList.add('show-slides');
  };
  const hideSlideScroll = function () {
    elements.bodyEl.classList.remove('show-slides');
  };
  // toggle visible work
  const removeWorkClasses = function () {
    elements.workContent.classList.remove(
      'clic',
      'argus',
      'wibl',
      'pushing',
      'newyddion'
    );
  };
  const showWorkStuff = function () {
    elements.bodyEl.classList.add('show-work');
    removeWorkClasses();
    elements.workContent.classList.add(showWork);
  };
  const hideWorkStuff = function () {
    elements.bodyEl.classList.remove('show-work');
  };
  // about slide
  const showAboutStuff = function () {
    elements.bodyEl.classList.add('show-about');
  };
  const hideAboutStuff = function () {
    elements.bodyEl.classList.remove('show-about');
  };

  //  animation timelines
  const animations = {
    showSlides: new TimelineMax({
      paused: true,
      onComplete: showSlideScroll,
      onReverseComplete: hideSlideScroll,
    }),
    showSlidesTab: new TimelineMax({ paused: true }),
    showWork: new TimelineMax({
      paused: true,
      onStart: showWorkStuff,
      onReverseComplete: hideWorkStuff,
    }),
    showNav: new TimelineMax({ reversed: true }),
    showAbout: new TimelineMax({
      reversed: true,
      onStart: showAboutStuff,
      onReverseComplete: hideAboutStuff,
    }),
  };

  animations.showSlides
    // footer cta
    .to(elements.viewWork, duration.main, {
      y: '100%',
      autoAlpha: 0,
      ease: easing.back2,
    })
    .to(
      elements.footerCTA,
      duration.main,
      { y: '100%', autoAlpha: 0, ease: easing.back2 },
      `-=${duration.main * 0.6}`
    )
    .to(
      elements.intro,
      duration.main,
      { y: -24, opacity: 0, ease: easing.back2 },
      `-=${duration.main * 0.1}`
    )
    .to(
      elements.slideIntroImageMob,
      duration.main * 2,
      { top: '50%', autoAlpha: 0, ease: easing.back1 },
      `-=${duration.main}`
    )
    // colour block
    .from(
      elements.slideBlockMob,
      duration.main * 2,
      {
        top: 300,
        backgroundImage: 'radial-gradient(50% 100%, #FDFBFB 0%, #EBEDEE 100%)',
        ease: easing.back1,
      },
      `-=${duration.main}`
    )
    .from(
      elements.slideBlockMob,
      duration.main * 2,
      { height: '100vh', ease: easing.back2 },
      `-=${duration.main * 2 - duration.main}`
    )
    // slide content
    .from(
      elements.slideImageMob,
      duration.main * 2,
      { autoAlpha: 0, y: 40, ease: easing.back3 },
      `-=${duration.main}`
    )
    .from(
      elements.slideCopyMob,
      duration.main * 2,
      { minHeight: '50vh', ease: easing.back2 },
      `-=${duration.main * 2}`
    )
    .from(elements.slideCopyMob, duration.main, {
      opacity: 0,
      y: 40,
      ease: easing.linear,
    })
    .from(
      elements.slideCTA,
      duration.main,
      { autoAlpha: 0, y: 40, ease: easing.linear },
      `-=${duration.main / 2}`
    );

  animations.showSlidesTab
    // colour block
    .to(elements.slideIntroImageTab, duration.main, {
      y: '-50%',
      autoAlpha: 0,
      ease: easing.back1,
    })
    .fromTo(
      elements.slideBlockTab,
      duration.main * 2,
      { top: '100%', ease: easing.back1 },
      { top: '10px', ease: easing.back1 }
    )
    // slide content
    .from(elements.slideImageTab, duration.main * 2, {
      autoAlpha: 0,
      y: 40,
      ease: easing.back3,
    })
    .from(
      elements.slideCopyTab,
      duration.main * 2,
      { autoAlpha: 0, y: 40, ease: easing.back1 },
      `-=${duration.main * 1.5}`
    )
    .from(
      elements.scrollIcon,
      duration.main * 2,
      { autoAlpha: 0, y: 40, ease: easing.back1 },
      `-=${duration.main * 1.5}`
    );

  animations.showWork
    // hide some slide content
    .fromTo(
      '.slide__block--tablet',
      duration.main * 2,
      { left: '50%', ease: easing.back1 },
      { left: '10px', ease: easing.back1 }
    )
    .to(
      '.slide__copy, .slide__caption, .slide__text, .slide__link',
      duration.main * 2,
      { autoAlpha: 0, ease: easing.linear },
      `-=${duration.main * 2}`
    )
    .to(
      elements.scrollIcon,
      duration.main * 2,
      { autoAlpha: 0, y: 40, ease: easing.back1 },
      `-=${duration.main * 2}`
    )
    .to(
      '.slide__image',
      duration.main * 2,
      { y: '-30vh', autoAlpha: 0, ease: easing.back1 },
      `-=${duration.main}`
    )
    .to(
      '.slide__cta',
      duration.main * 2,
      { y: '-30vh', autoAlpha: 0, ease: easing.back1 },
      `-=${duration.main}`
    )
    // show work content - work container already exposed via adding a class
    .from(
      elements.workContent,
      duration.main * 2,
      { top: '100%', ease: easing.back1 },
      `-=${duration.main}`
    )
    .from(
      elements.workContentInner,
      duration.main * 2,
      { y: '10vh', autoAlpha: 0, ease: easing.back1 },
      `-=${duration.main * 2}`
    )
    .from(
      elements.workClose,
      duration.main * 2,
      { y: '-100%', autoAlpha: 0, ease: easing.back1 },
      `-=${duration.main * 1.5}`
    )
    .to(
      elements.workContent,
      duration.main,
      { scrollTo: 0, ease: easing.back1 },
      `-=${duration.main * 2}`
    );

  animations.showNav
    .to(elements.menuRow2, duration.main / 2, { width: 0, ease: easing.back1 })
    .to(
      elements.menuRow1,
      duration.main / 2,
      { top: 6, ease: easing.back1 },
      `-=${duration.main / 2}`
    )
    .to(
      elements.menuRow3,
      duration.main / 2,
      { top: 6, ease: easing.back1 },
      `-=${duration.main / 2}`
    )
    .to(elements.menuRow1, duration.main / 2, {
      rotation: 45,
      ease: easing.back1,
    })
    .to(
      elements.menuRow3,
      duration.main / 2,
      { rotation: -45, ease: easing.back1 },
      `-=${duration.main / 2}`
    )
    .to(
      elements.navLinkTitle,
      duration.main,
      { width: 0, ease: easing.back1 },
      `-=${duration.main / 2}`
    )
    .from(
      '.nav__link--mobile',
      duration.main,
      { width: 0, ease: easing.back1 },
      `-=${duration.main}`
    );

  animations.showAbout
    .from(
      elements.aboutContent,
      duration.main * 2,
      { top: '100%', ease: easing.back1 },
      `-=${duration.main}`
    )
    .from(
      elements.aboutClose,
      duration.main * 2,
      { y: '-100%', autoAlpha: 0, ease: easing.back1 },
      `-=${duration.main * 1.5}`
    );

  // event listeners
  const listening = function () {
    // open work - cta button
    elements.viewWork.addEventListener('click', function (e) {
      e.preventDefault();
      animations.showSlides.play().duration(1.8);
      animations.showSlidesTab.play();
    });

    // open work - work image
    for (var i = 0; i < elements.viewWorkImage.length; i++) {
      elements.viewWorkImage[i].addEventListener('click', function (e) {
        e.preventDefault();
        // show work div
        animations.showSlides.play().duration(1.8);
        animations.showSlidesTab.play();
      });
    }

    // open work - nav link
    elements.navLinkWork.addEventListener('click', function (e) {
      e.preventDefault();
      animations.showSlides.play().duration(1.8);
      animations.showSlidesTab.play();
    });

    // close work
    elements.navLogo.addEventListener('click', function (e) {
      e.preventDefault();
      elements.slides.classList.add('no-snap');
      TweenMax.to(elements.slides, duration.main, {
        scrollTo: 0,
        onComplete: function () {
          elements.slides.classList.remove('no-snap');
        },
      });
      animations.showSlides.reverse().duration(1.2);
      animations.showSlidesTab.reverse(1);
    });

    // control showing specific work
    for (var i = 0; i < elements.showBtn.length; i++) {
      elements.showBtn[i].addEventListener('click', function (e) {
        e.preventDefault();
        // show work div
        console.log(this.dataset.work);
        showWork = this.dataset.work;
        animations.showWork.play();
      });
    }

    // close current work
    elements.workClose.addEventListener('click', function (e) {
      e.preventDefault();
      // hide work div
      TweenMax.to(elements.workContent, duration.main, { scrollTo: 0 });
      animations.showWork.reverse();
      elements.newyddionVideo.pause();
    });

    // control navigation
    elements.menuIcon.addEventListener('click', function (e) {
      e.preventDefault();
      animations.showNav.reversed(!animations.showNav.reversed());
    });

    // open about
    elements.navLinkAbout.addEventListener('click', function (e) {
      e.preventDefault();
      animations.showAbout.play();
    });

    // close about
    elements.aboutClose.addEventListener('click', function (e) {
      e.preventDefault();
      animations.showAbout.reverse();
    });

    elements.yearsSpan.innerHTML = new Date().getFullYear() - 2000;
  };

  // expose some stuff for use
  return {
    init: function () {
      listening();
    },
  };
})();

sceneController.init();
